import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn:
    "https://329552c9deac45d6985ee36ea3d092b7@o101288.ingest.sentry.io/5528631",
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: 1.0,
});
